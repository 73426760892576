import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ClientRegistrationService } from '../../services/registration/client/client-registration.service';
import { RegistrationData } from '../../models/interface/registration-data.interface';
import { LanguageService } from '../../services/language/language.service';
import { RegistrationState } from '../../models/interface/registration-state.interface';
import { EventAlertService } from '../../services/event/event-alert.service';
import { ErrorMessageFormatter } from '../../utils/attempts-message-formatter/error-message-formatter';
import { SessionTimerService } from '../../services/session/session-timer.service';

@Component({
  selector: 'regas-client-registration',
  templateUrl: './client-registration.component.html',
  providers: [SessionTimerService],
})
export class ClientRegistrationComponent implements OnInit, OnDestroy {
  private readonly endSubscription$ = new Subject<boolean>();
  email: string;
  validationCode: string;
  state$: Observable<RegistrationState>;
  organisation: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly registrationService: ClientRegistrationService,
    private readonly languageService: LanguageService,
    private readonly eventAlertService: EventAlertService,
    private readonly sessionTimerService: SessionTimerService,
  ) {}

  ngOnInit(): void {
    this.initDataAndValidate();
    this.state$ = this.registrationService.getState().pipe(
      takeUntil(this.endSubscription$),
      tap((state: RegistrationState) => {
        if (state.token && state.data) {
          this.sessionTimerService.start(this.clearSession);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.sessionTimerService.stop();
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  private readonly clearSession = (): void => {
    this.registrationService.clearState();
    this.eventAlertService.setMessage({ message: 'error.sessionExpired' });
  };

  onRegistration(registrationData: RegistrationData): void {
    this.eventAlertService.clearMessage();
    this.registrationService
      .preValidate({
        ...registrationData,
        validationCode: this.validationCode,
        emailAddress: this.email,
        language: this.languageService.getLanguage(),
      })
      .subscribe();
  }

  onTwoFactor(state: RegistrationState, code: string): void {
    this.registrationService.registerClient(state, code).subscribe(
      () => {
        this.eventAlertService.setMessage({
          message: 'success.registrationCompleted',
          type: 'success',
        });
        this.router.navigate(['client', this.organisation]);
      },
      (errorResponse: HttpErrorResponse) => {
        this.registrationService.handleError(
          ErrorMessageFormatter.produceInvalidInputMessage(
            errorResponse,
            'error.invalidVerificationCode',
            'registration.error.unexpected',
          ),
        );
      },
    );
  }

  private initDataAndValidate(): void {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.endSubscription$),
        tap(queryParams => {
          this.validationCode = queryParams?.validationcode || '';
        }),
        switchMap(() => this.activatedRoute.params),
        tap(params => {
          this.organisation = params['substitution'];
        }),
        switchMap(() => this.fillEmail()),
      )
      .subscribe();
  }

  private fillEmail(): Observable<object> {
    return this.registrationService
      .getEmailForActivationRequest(this.validationCode)
      .pipe(
        take(1),
        switchMap(response => {
          this.email = response.emailAddress;
          return EMPTY;
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.registrationService.handleError(errorResponse);
          return EMPTY;
        }),
      );
  }
}
